import ModalWrapper from '../wrappers/ModalWrapper';
import { Formik, Form } from 'formik';
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import CustomDatePicker from '../ui/CustomDatePicker';
import InputBox from '../ui/InputBox';
import { validationAddContainerModal } from '../lib/validationSchema';

interface AddContainerModalProps {
    open: boolean;
    close: () => void;
    onAddJobContainer: any;
    initialValues: any;
}

const AddContainerModal = ({ open, close, onAddJobContainer, initialValues = null }: AddContainerModalProps) => {

    const defaultValues = {
        container_no: '',
        seal_no: '',
        declared_gross_weight: '',
        wharf_slot: '',
        empty_container_release_number: '',
        cntr_last_day: '',
        over_weight: false,
    };
    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        onAddJobContainer(values);
        setSubmitting(false);
        resetForm();
        close();
    };

    const handleClose = (resetForm: () => void) => {
        resetForm(); 
        close();
    };
    return (
        <Formik
        initialValues={initialValues || defaultValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationAddContainerModal}

        >
            {({ isSubmitting, handleChange, setFieldValue, values, errors, touched,resetForm}) => (
                <ModalWrapper
                    open={open}
                    onClose={() => handleClose(resetForm)}
                    heading={initialValues?"Edit Container Details":"Add Container Details"}
                >
                    <Form>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.over_weight}
                                    onChange={(e) =>
                                        setFieldValue(`over_weight`, e.target.checked)
                                    }
                                />
                            }
                            label="Tick if container is overweight"
                            style={{ margin: "20px 0" }}
                        />
                        <Grid container spacing={2} style={{ margin: "10px 0" }}>
                            <Grid item xs={6}>
                                <InputBox
                                    value={values.container_no}
                                    onChange={handleChange}
                                    name={`container_no`}
                                    type="text"
                                    label="Container No."
                                    error={errors.container_no && touched.container_no ? errors.container_no : null}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputBox
                                    value={values.seal_no}
                                    onChange={handleChange}
                                    name={`seal_no`}
                                    type="text"
                                    label="Seal No."
                                    error={errors.seal_no && touched.seal_no ? errors.seal_no : null}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ margin: "10px 0" }}>
                            <Grid item xs={6}>
                                <InputBox
                                    value={values.empty_container_release_number}
                                    onChange={handleChange}
                                    name={`empty_container_release_number`}
                                    type="text"
                                    label="EC Seal No."
                                    error={errors.empty_container_release_number && touched.empty_container_release_number ? errors.empty_container_release_number : null}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomDatePicker
                                    selectedDate={values.wharf_slot}
                                    handleDateChange={handleChange}
                                    name={`wharf_slot`}
                                    placeholder="Wharf Slot"
                                    error={errors.wharf_slot && touched.wharf_slot ? errors.wharf_slot : null}
                                />

                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ margin: "10px 0" }}>
                            <Grid item xs={6}>
                                <CustomDatePicker
                                    selectedDate={values.cntr_last_day}
                                    handleDateChange={handleChange}
                                    name={`cntr_last_day`}
                                    placeholder="Cntr Last Day"
                                    error={errors.cntr_last_day && touched.cntr_last_day ? errors.cntr_last_day : null}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputBox
                                    value={values.declared_gross_weight}
                                    onChange={handleChange}
                                    name={`declared_gross_weight`}
                                    type="text"
                                    label="Declared Gross Weight (kg)"
                                    error={errors.declared_gross_weight && touched.declared_gross_weight ? errors.declared_gross_weight : null}
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth color="secondary" variant="contained" disabled={isSubmitting} style={{margin:"20px 0"}}>
                            Submit
                        </Button>
                    </Form>
                </ModalWrapper>
            )}
        </Formik>
    );
};

export default AddContainerModal;
