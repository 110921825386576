export const Mi4uRefNumFunc = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); 
    let day = String(date.getDate()).padStart(2, '0');
    let newDate = `${year}${month}${day}`;

    let text = "";
    const possible = "0123456789";
    for (let i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return `MI4U-${newDate}-${text}`;
}
