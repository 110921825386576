import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import useSearch from '../hooks/useSearch';

interface CustomSearchInputProps {
  label?: string;
  onSearch: (query: string) => void;
  variant?: 'outlined' | 'filled' | 'standard';
  [key: string]: any; 
}

const CustomSearchInput: React.FC<CustomSearchInputProps> = ({
  label,
  onSearch,
  ...props
}) => {
  const { query, setQuery, debouncedQuery } = useSearch();

  React.useEffect(() => {
    if (debouncedQuery) {
      onSearch(debouncedQuery);
    }
  }, [debouncedQuery, onSearch]);

  return (
    <TextField
      label={label || 'Search'}
      value={query}
      onChange={(e) => setQuery(e.target.value)}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => onSearch(debouncedQuery)}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        style: {
          fontWeight: 'bold',
        },
      }}
      {...props}
    />
  );
};

export default CustomSearchInput;
