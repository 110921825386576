import { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import SectionHeader from '../../../Components/Common/SectionHeader/SectionHeader';
import CustomSearchInput from '../../ui/CustomSearchInput';
import { WebStorage } from '../../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../../Types/Constants';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        height: 700,
        width: '100%',
        overflowX: 'auto',
    },
    dataGrid: {
        minWidth: 1200,
    },
    search: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "20px 0",
    },
    errorMessage: {
        color: 'red',
        margin: '20px 0',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
    }
}));

const storage = new WebStorage();

const AllJobsV2 = (props: any) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState({
        loading: true,
        totalRows: 0,
        rowsPerPageOptions: [5, 10, 20],
        page: 1,
        pageSize: 10,
        rows: [],
    });

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        setError(null);
        setLoading(true);
        try {
            const myHeaders = new Headers({
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            });

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL+"/v1/job/get/optimize?classification=transport&page=all&offset=0",
                requestOptions
            );

            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const result = await response.json();
            if (!result || result.length === 0) {
                throw new Error('No jobs found.');
            }

            const jobs = result.map((job: any) => {
                const jobAddressArray = Array?.isArray(job.job_address) ? job.job_address : [job.job_address];

                return {
                    id: job.id,
                    company_id: job.company_id,
                    movement_type_id: job.movement_type_id,
                    shipment_no: job.shipment_no,
                    job_type_id: job.job_type_id,
                    reference: job.reference,
                    customer_reference: job.customer_reference,
                    trip_type: job.trip_type,
                    transport_type: job.transport_type,
                    service_type: job.service_type,
                    post_to: job.post_to,
                    buy_price: job.buy_price,
                    pickup_date_time: jobAddressArray[0]?.pickup_date_time,
                    delivery_date_time: jobAddressArray[jobAddressArray?.length - 1]?.delivery_date_time,
                    status: job.status,
                };
            });

            setData((prev) => ({
                ...prev,
                loading: false,
                rows: jobs,
                totalRows: jobs.length,
            }));
        } catch (error) {
            console.error('Fetch error:', error);
            setError(error.message);
            setData((prev) => ({ ...prev, loading: false }));
        }
        finally {
            setLoading(false);
        }
    };

    const columns: GridColDef[] = [
        { field: 'company_id', headerName: 'Company', minWidth: 150 },
        { field: 'movement_type_id', headerName: 'Movement Type', minWidth: 180 },
        { field: 'shipment_no', headerName: 'Shipment Number', minWidth: 150 },
        { field: 'job_type_id', headerName: 'Job Type', minWidth: 150 },
        { field: 'reference', headerName: 'MI4U Ref', minWidth: 120 },
        { field: 'customer_reference', headerName: 'Customer Ref', minWidth: 150 },
        { field: 'trip_type', headerName: 'Trip Type', minWidth: 120 },
        { field: 'transport_type', headerName: 'Transport Type', minWidth: 150 },
        { field: 'service_type', headerName: 'Service Type', minWidth: 150 },
        { field: 'post_to', headerName: 'Post To', minWidth: 120 },
        { field: 'buy_price', headerName: 'Buy Price', minWidth: 120 },
        { field: 'pickup_date_time', headerName: 'Pickup Date', minWidth: 150 },
        { field: 'delivery_date_time', headerName: 'Delivery Date', minWidth: 150 },
        { field: 'status', headerName: 'Status', minWidth: 150 },
    ];

    const handleSearch = (query: string) => {
        console.log("Searching for:", query);
    };

    if (loading) {
        return <div className={classes.loading}><CircularProgress /></div>;
    }
    return (
        <>
            <SectionHeader subtitle="Transport Management" title="All Jobs" />
            <div className={classes.search}>
                <CustomSearchInput
                    label="Please enter job reference"
                    onSearch={handleSearch}
                    variant="outlined"
                />
            </div>

            {error && <div className={classes.errorMessage}>{error}</div>}

            <div className={classes.root}>
                <DataGrid
                    className={classes.dataGrid}
                    onRowClick={props.onRowClick}
                    columns={columns}
                    disableSelectionOnClick
                    localeText={{ noRowsLabel: 'No records found' }}
                    paginationMode="client"
                    sortingMode="client"
                    loading={loading}
                    rowCount={data.totalRows}
                    rowsPerPageOptions={data.rowsPerPageOptions}
                    page={data.page - 1}
                    pageSize={data.pageSize}
                    rows={data.rows}
                    onPageChange={(newPage) => { setData((prev) => ({ ...prev, page: newPage + 1 })); }}
                    onPageSizeChange={(newPageSize) => {
                        setData((prev) => ({ ...prev, pageSize: newPageSize, page: 1 }));
                    }}
                    disableColumnMenu
                />
            </div>
        </>
    );
}

export default AllJobsV2;
