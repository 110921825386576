import { useState, useEffect } from 'react';

const useSearch = (delay: number = 300) => {
  const [query, setQuery] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState<string>(query);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [query, delay]);

  return {
    query,
    setQuery,
    debouncedQuery,
  };
};

export default useSearch;
