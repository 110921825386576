import ModalWrapper from '../wrappers/ModalWrapper'
import { ADDRESS_GROUPS, AU_STATES, COUNTRIES, NZ_STATES, PALLET_ACCOUNT_ACTION, PALLET_ACCOUNT_TYPE, STATE_TYPE, users } from '../lib/constants'
import { useEffect, useState } from 'react';
import MultiSelectDropDownComp from '../ui/MultiSelectDropDownComp';
import SelectorComp from '../ui/SelectorComp';
import { Box, Button, Grid } from '@material-ui/core';
import InputBox from '../ui/InputBox';
import { styled } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Form, Formik } from 'formik';
import { IAddress } from '../../../../Application/DTOs/AddressDto/AddressDto.types';
import { toast } from 'react-toastify';

interface EditAddressModalProps {
  open: boolean;
  close: (isSaved?:boolean) => void
  addressId:number|string
  addresses:IAddress[]
}
const commonFlexStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
};
const ButtonFlex = styled(Box)({
  ...commonFlexStyles,
  gap: "20px"
});
const MarginSpace = styled(Box)({
  padding: "10px 0"
});

const ButtonContainer = styled(Box)({
  ...commonFlexStyles,
  margin: "20px 0"
});
const EditAddressModal = ({ open, close,addressId,addresses}: EditAddressModalProps) => {
  const [togglePallet, setTogglePallet] = useState<boolean>(false)

  const [initialValues, setInitialValues] = useState({
    searchaddress: "",
    country: "",
    groupType: "",
    companyName: "",
    mpdId: "",
    address: "",
    timeslot: "",
    receivingHours: "",
    instruction: "",
    palletAccountType: "",
    palletAccountAction: "",
    palletAccount: "",
    stateRegionType: "",
    stateType: "",
    suburbCity: "",
    postcode: "",
    latitude: "",
    longitude: ""
  });

  useEffect(() => {
    if (addresses?.length && addressId) {
      const singleAddress = addresses?.find((item) => item.id === addressId);      
      if (singleAddress) {
        setInitialValues({
          searchaddress: "",
          country: singleAddress.country || "",
          groupType: "",
          companyName: singleAddress.company_name || "",
          mpdId: "",
          address: singleAddress.street_number || "",
          timeslot: singleAddress.email || "",
          receivingHours: singleAddress.receiving_hours || "",
          instruction: singleAddress.instruction || "",
          palletAccountType: "",
          palletAccountAction: "",
          palletAccount: "",
          stateRegionType: singleAddress.state || "",
          stateType: "",
          suburbCity: singleAddress.city || "",
          postcode: singleAddress.postcode || "",
          latitude: singleAddress.latitude || "",
          longitude: singleAddress.longitude || ""
        });
      }
    }
  }, [addresses, addressId]);


  const handleTogglePallet = () => {
    setTogglePallet((prev) => !prev);
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const payload = {
        address: values.address,
        company_id: values.company,
        company_name: values.companyName,
        country: values.country,
        address_group: values.groupType,
        instruction: values.instruction,
        latitude: values.latitude,
        longitude: values.longitude,
        mpd_id: values.mpdId,
        postcode: values.postcode,
        receiving_hours: values.receivingHours,
        palletAccount: values.palletAccount,
        palletAccountAction: values.palletAccountAction,
        palletAccountType: values.palletAccountType,
        state: values.stateRegionType,
        state_type: values.stateType,
        city: values.suburbCity,
        email: values.timeslotEmail,
        is_common: true,
        is_land_freight: true,
        is_sea_freight: false,
        street_number: "", 
        palletAccounts: [],
        addressId
      }
      
      console.log("values", values)
      console.log("payload", payload)

    } catch (err) {
      toast.error('Please fill all required fields');
    }
  }

  const handleClose = () => {
    close()
  }
  
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, handleChange, setFieldValue, values, errors, touched, resetForm }) => (

          <ModalWrapper
            open={open}
            onClose={handleClose}
            heading="Edit Address"
          >
            <Form>
              <MarginSpace>
                <MultiSelectDropDownComp
                  options={users}
                  label="Select Address"
                  value={values.searchaddress}
                  getOptionLabel={(option) => option.name}
                  getOptionId={(option) => option.id}
                  onChange={handleChange}
                  name="searchaddress"
                />
                
              </MarginSpace>
              <MarginSpace>
                <Grid container spacing={2} >
                  <Grid item xs={6} >
                    <SelectorComp
                      label="Country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      options={COUNTRIES}
                    />
                  </Grid>
                  <Grid item xs={6} >
                    <SelectorComp
                      label="Group"
                      onChange={handleChange}
                      options={ADDRESS_GROUPS}
                      value={values.groupType}
                      name={'groupType'}
                    />
                  </Grid>

                </Grid>
              </MarginSpace>

              <MarginSpace>
                <Grid container spacing={2}>

                  <Grid item xs={6} >
                    <InputBox
                      label="Company Name"
                      onChange={handleChange}
                      name="companyName"
                      type="text"
                      disabled={false}
                      value={values.companyName}
                    />
                  </Grid>

                  <Grid item xs={6} >
                    <InputBox
                      label="MPD ID"
                      onChange={handleChange}
                      name="mpdId"
                      type="text"
                      disabled={false}
                      value={values.mpdId}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>

              <MarginSpace>
                <Grid container spacing={2}>
                  <Grid item xs={6} >
                    <InputBox
                      label="Address"
                      onChange={handleChange}
                      name="address"
                      type="text"
                      value={values.address}
                      disabled={false}
                    />
                  </Grid>

                  <Grid item xs={6} >
                    <InputBox
                      label="Timeslot Email"
                      onChange={handleChange}
                      name="timeslot"
                      type="text"
                      disabled={false}
                      value={values.timeslot}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>

              <MarginSpace>
                <InputBox
                  label="Receiving Hours"
                  multiline
                  onChange={handleChange}
                  name="receivingHours"
                  type="text"
                  disabled={false}
                  value={values.receivingHours}
                />
              </MarginSpace>

              <MarginSpace>
                <InputBox
                  label="Instructions"
                  multiline
                  onChange={handleChange}
                  name="instruction"
                  type="text"
                  disabled={false}
                  value={values.instruction}
                />
              </MarginSpace>
              {togglePallet && <MarginSpace>
                <Grid container spacing={3}>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="Pallet Account Type"
                      onChange={handleChange}
                      options={PALLET_ACCOUNT_TYPE}
                      value={values.palletAccountType}
                      name={'palletAccountType'}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="Pallet Account Action"
                      onChange={handleChange}
                      options={PALLET_ACCOUNT_ACTION}
                      value={values.palletAccountAction}
                      name={'palletAccountAction'}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <InputBox
                      label="Pallet Account"
                      onChange={handleChange}
                      name="palletAccount"
                      type="text"
                      disabled={false}
                      value={values.palletAccount}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>}


              <MarginSpace>
                <Grid container spacing={3}>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="State/ Region"
                      onChange={handleChange}
                      value={values.stateRegionType}
                      name={'stateRegionType'}
                      options={values.country === "australia" ? AU_STATES : values.country === "new_zealand" ? NZ_STATES : []}

                    />
                  </Grid>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="State Type"
                      onChange={handleChange}
                      options={STATE_TYPE}
                      value={values.stateType}
                      name={'stateType'}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <InputBox
                      label="Suburb/ City"
                      onChange={handleChange}
                      name="suburbCity"
                      type="text"
                      disabled={false}
                      value={values.suburbCity}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>


              <MarginSpace>
                <Grid container spacing={3}>
                  <Grid item xs={4} >
                    <InputBox
                      label="Postcode"
                      onChange={handleChange}
                      name="postcode"
                      type="text"
                      disabled={false}
                      value={values.postcode}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <InputBox
                      label="Latitude"
                      onChange={handleChange}
                      name="latitude"
                      type="text"
                      disabled={false}
                      value={values.latitude}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <InputBox
                      label="Longitude"
                      onChange={handleChange}
                      name="longitude"
                      type="text"
                      disabled={false}
                      value={values.longitude}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>

              <ButtonContainer>
                <Button variant="contained" color="primary" type='button' onClick={handleTogglePallet}>Add Pallet</Button>
                <ButtonFlex>
                  <Button variant="contained" color="default" onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" color="primary" startIcon={<EditIcon />} type='submit'>Edit</Button>
                </ButtonFlex>
              </ButtonContainer>
            </Form>
          </ModalWrapper>
        )}

      </Formik>
    </>
  )
}

export default EditAddressModal