import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';
interface MultiSelectDropDownCompProps<T> {
  options: T[]; // Generic type T for options
  label: string;
  value: T | null; // Change to T | null to support the selection
  getOptionLabel: (option: T) => string; // Dynamically extracts the label
  getOptionId: (option: T) => string | number; // Extracts the unique identifier for the option
  onChange: (event: React.ChangeEvent<{}>, value: T | null) => void;
  name: string;
  error?: any
}

const MultiSelectDropDownComp = <T,>({
  options,
  label,
  value,
  getOptionLabel,
  getOptionId,
  onChange,
  name,
  error
}: MultiSelectDropDownCompProps<T>) => {

  return (
    <FormControl fullWidth variant="outlined" >
      <Autocomplete
        options={options}
        getOptionLabel={getOptionLabel}
        value={options?.find(option => getOptionId(option) === value) || null} // Match the selected value
        fullWidth
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            name={name}
            error={!!error}
          />
        )}
      />
      <FormHelperText style={{ color: "#f44336" }}>{error}</FormHelperText>
    </FormControl>

  );
};

export default MultiSelectDropDownComp;
