import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FormHelperText } from '@material-ui/core';

interface SelectorCompProps {
  label: string;
  value: string | number;
  onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  options: { value: string | number; label: string }[];
  disabled?: boolean;
  name:string;
  error?:any
}

const SelectorComp: React.FC<SelectorCompProps> = ({ label, value, onChange, options, disabled = false,name,error}) => {
  return (
    <FormControl fullWidth variant="outlined" disabled={disabled}>
      <InputLabel id={`select-${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`select-${label}-label`}
        id={`select-${label}`}
        value={value}
        onChange={onChange}
        label={label}
        name={name}
        error={!!error}
      >
        <MenuItem value="">
          <em>{`Select ${label}`}</em>
        </MenuItem>

        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText style={{color:"#f44336"}}>{error}</FormHelperText>

    </FormControl>
  );
};

export default SelectorComp;
