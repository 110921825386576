import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Button, Typography } from '@material-ui/core';
import { Edit, Delete, Add } from '@material-ui/icons';

const columns = [
  'Container No.',
  'Seal No.',
  'EC Release No.',
  'Wharf Slot',
  'CNTR LFD',
  'Declared Gross Weight',
  'Overweight',
  'Upload Documents',
  'Actions',
];

const CreateJobTable = ({ initialValuesOld, onClickAddNew, onEditContainer, onDeleteContainer }) => {
  const { jobContainers } = initialValuesOld;

  return (
    <TableContainer component={Paper} style={{ margin: "20px 0" }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {jobContainers?.length > 0 ? (
            jobContainers?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.container_no}</TableCell>
                <TableCell>{row.seal_no}</TableCell>
                <TableCell>{row.empty_container_release_number}</TableCell>
                <TableCell>{row.wharf_slot}</TableCell>
                <TableCell>{row.cntr_last_day}</TableCell>
                <TableCell>{row.declared_gross_weight}</TableCell>
                <TableCell>{row.over_weight ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  NA
                </TableCell>
                <TableCell>
                  <IconButton aria-label="edit" onClick={() => onEditContainer(row)}><Edit /></IconButton>
                  <IconButton aria-label="delete" onClick={() => onDeleteContainer(row.id)}><Delete /></IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={9} align="center">
                <Typography variant="body2" color="textSecondary">No data available</Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={9}>
              <Button startIcon={<Add />} color="primary" type="button" onClick={onClickAddNew}>Add New</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CreateJobTable